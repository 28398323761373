.select-multiple-simple {
  --select-single-padding: 0.571rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--border-main-color);
  padding: 0 var(--select-single-padding);
  pointer-events: auto;
  position: relative;
  border-radius: 0.3571rem;
  height: 2.857rem;
}

.select-multiple-simple .select-multiple-simple-box {
  --select-single-padding: 0.571rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
  justify-content: space-between;
  position: relative;
}

.select-multiple-simple .select-multiple-simple-selected-options {
  display: flex;
  position: absolute;
  width: calc(100% - 1.4286rem);
  gap: 0.2857rem;
  overflow: hidden;
  align-items: center;
}

.select-multiple-simple .select-multiple-simple-selected-options:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(90deg, transparent 80%, #ffffff 100%);
  right: 0;
  bottom: 0;
}

.select-multiple-simple
  .select-multiple-simple-selected-options
  .selected-option-tag {
  display: flex;
  align-items: center;
  min-width: fit-content;
  gap: 0.1429rem;
  min-height: 1.5714rem;
  padding: 0.2143rem 0.4286rem 0.2143rem  0.4286rem;
  background-color: var(--grey-palette-01);
  font-size: 0.7143rem;
  font-weight: var(--font-weight-medium);
  color: #ffffff;
  border-radius: 0.2857rem;
}

.select-multiple-simple .arrow-down-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
  width: 1.2857rem;
  height: auto;
}

.select-multiple-simple .arrow-down-icon path {
  fill: var(--secondary-color);
}

.select-multiple-simple.disabled .arrow-down-icon {
  cursor: pointer;
  min-width: 1.2857rem;
  height: 1.2857rem;
  cursor: unset;
}

.select-multiple-simple:hover,
.select-multiple-simple:focus-within {
  border-color: var(--primary-color);
}

.select-multiple-simple.error,
.select-multiple-simple.error:hover {
  border-color: var(--error-color);
}

.select-multiple-simple.disabled,
.select-multiple-simple.disabled:hover {
  border-color: var(--grey-palette-02);
  background-color: var(--grey-palette-03);
}

.select-multiple-simple .native-input {
  flex-grow: 1;
  padding: 0;
  padding-left: 0.286rem;
  min-width: 2.8571rem;
  caret-color: var(--secondary-color);
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

.select-multiple-simple.has-icon .native-input {
  padding-left: 0.429rem;
}

.select-multiple-simple .native-input:focus {
  outline: none;
}

.select-multiple-simple .native-input::placeholder {
  color: var(--placeholder-color);
}

.select-multiple-simple.disabled .native-input {
  color: var(--grey-palette-01);
}

.select-multiple-simple.disabled .native-input::placeholder {
  color: var(--placeholder-disabled-color);
}

.select-multiple-simple .select-multiple-simple-icon {
  display: flex;
  align-items: center;
}

.select-multiple-simple:hover .select-multiple-simple-icon svg,
.select-multiple-simple:focus-within .select-multiple-simple-icon svg {
  fill: var(--primary-color);
}

.select-multiple-simple.disabled,
.select-multiple-simple.disabled:hover {
  background-color: var(--grey-palette-03);
}

.select-multiple-simple.disabled .arrow-down-icon path,
.select-multiple-simple.disabled:hover .arrow-down-icon path {
  fill: var(--placeholder-disabled-color);
}

.select-multiple-simple.error .select-multiple-simple-icon svg,
.select-multiple-simple.error:hover .select-multiple-simple-icon svg,
.select-multiple-simple.error:focus-within .select-multiple-simple-icon svg {
  fill: var(--error-color);
}

.select-multiple-simple .select-multiple-simple-options-panel {
  position: absolute;
  top: calc(100% + 0.0714rem);
  left: 0;
  min-width: 100%;
  z-index: 90;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #ffffff;
  color: var(--text-main-color);
  max-height: 11.6429rem;
  padding: 0.8571rem 0 0.4286rem 0;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0.3571rem;
}

.select-multiple-simple
  .select-multiple-simple-options-panel
  .multiple-select-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding: 0 0.857rem 0.1429rem 0.857rem;
  gap: 6px;
}

.select-multiple-simple
  .select-multiple-simple-options-panel
  .multiple-select-actions
  .mini-text-button {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 0.857rem;
  font-weight: var(--font-weight-bold);
}

.select-multiple-simple
  .select-multiple-simple-options-panel
  .multiple-select-actions
  .mini-text-button:hover {
  color: var(--secondary-color);
}

.select-multiple-simple
  .select-multiple-simple-options-panel
  .multiple-select-actions
  .dot-separator {
  display: flex;
  align-items: center;
  width: 0.1429rem;
  height: 0.1429rem;
  border-radius: 50%;
  background-color: var(--grey-palette-02);
}

.select-multiple-simple
  .select-multiple-simple-options-panel
  .multiple-select-option {
  min-height: 2.1429rem;
  padding: 0.357rem 0.857rem;
}

.select-multiple-simple
  .select-multiple-simple-options-panel
  .multiple-select-option.hover,
.select-multiple-simple
  .select-multiple-simple-options-panel
  .multiple-select-option:hover {
  background-color: var(--grey-palette-02);
  cursor: pointer;
}

.select-multiple-simple
  .select-multiple-simple-options-panel
  .multiple-select-option.selected {
  display: flex;
  justify-content: space-between;
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  background-color: var(--grey-palette-02);
}

.select-multiple-simple
  .select-multiple-simple-options-panel
  .multiple-select-option.selected
  .check-option
  path {
  fill: var(--primary-color);
}

.select-multiple-simple
  .select-multiple-simple-options-panel
  .multiple-select-option.selected
  .check-option
  path:first-child {
  fill: none;
}
