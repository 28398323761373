.tag {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  height: 1.7857rem;
  padding: 0.2857rem 0.7143rem;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--status-grey);
  color: #ffffff;
  white-space: nowrap;
  gap: 0.2857rem;
}

.tag.tiny-tag {
  height: 20px;
  padding: 0.2143rem 0.4286rem;
  border-radius: 3px;
  font-size: 0.8571rem;
}

.tag.has-icon {
  padding-left: 0.2857rem;
}

.tag .tag-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1.2857rem;
  height: 1.2857rem;
}

.tag .tag-icon path {
  fill: #ffffff;
}

.tag.status-red {
  background-color: var(--status-red);
}

.tag.status-blue {
  background-color: var(--status-blue);
}

.tag.status-yellow {
  background-color: var(--status-yellow);
  color: var(--text-main-color);
}

.tag.status-yellow-dimmed {
  background-color: var( --status-yellow-transparency-4);
  color: var(--text-main-color);
}

.tag.status-yellow.has-icon .tag-icon path {
  fill: var(--text-main-color);
}

.tag.status-grey {
  background-color: var(--status-grey);
}

.tag.status-green {
  background-color: var(--primary-color);
}

.tag.status-sky-blue {
  background-color: var(--color-valmet-sky-blue);
}

.tag.status-deep-blue {
  background-color: var(--color-valmet-deep-blue);
}
