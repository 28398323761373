.sub-side-bar {
  height: 100%;
  min-width: 4.2857rem;
  max-width: 4.2857rem;
  background-color: var(--grey-palette-03);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5714rem 0;
  gap: 1.1429rem;
}
