.text-button {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.text-button.primary {
    color: var(--secondary-color);
    font-weight: var(--font-weight-medium);
}

.text-button.primary:hover {
    color: var(--primary-color);
}

.text-button.white {
    color: white;
    font-weight: var(--font-weight-medium);
}

.text-button.white:hover {
    color: var(--primary-color);
}

.text-button.disabled {
    opacity: 0.3;
}

.text-button .text-button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-button.primary:hover .text-button-icon svg path {
    fill: var(--primary-color);
}
