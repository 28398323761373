.info-block {
  display: flex;
  flex-direction: column;
  gap: 0.2857rem;
}

.info-block .info-block-label {
  color: var(--grey-palette-01);
}

.info-block.status-red .info-block-value {
  color: var(--error-color);
}
