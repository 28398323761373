.info-block-comments {
  max-width: 21.4286rem;
}

.info-block-comments .info-block-text {
  display: flex;
  flex-direction: column;
  gap: .2143rem;
}

.info-block-comments .info-block-text:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.info-block-comments .info-block-text .info-block-value {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.info-block-comments .info-block-text .text-button-icon {
  width: 1rem;
  height: 1rem;
}

.info-block-comments .info-block-text:hover .text-button {
  color: var(--primary-color);
}

.info-block-comments .info-block-text:hover .text-button .text-button-icon svg path {
  fill: var(--primary-color);
}