.inventory-storage-locations .page-content .card-container.inventory-data .inventory-details-header {
    display: flex;
    justify-content: space-between;
}

.inventory-storage-locations .page-content .card-container.inventory-data .inventory-details-header .title {
    font-size: 1.2857rem;
    font-weight: var(--font-weight-medium);
}

.inventory-storage-locations .page-content .inventory-general-info {
    display: flex;
    flex-direction: row;
    gap: 1.4286rem;
}

.inventory-storage-locations .page-content .inventory-general-info .inventory-data {
    height: auto;
    min-width: 35.7143rem;
    flex: 1;
}

.inventory-storage-locations .page-content .inventory-general-info .status-cards {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 1.4286rem;
}

.inventory-storage-locations .page-filters {
    display: flex;
    justify-content: space-between;
    gap: 1.4286rem;
}

.inventory-storage-locations .page-filters .filters {
    display: flex;
    gap: 1.1429rem;
}

.inventory-storage-locations .action-button-filters {
    display: flex;
    justify-content: center;
    gap: 0.8571rem;
    margin-bottom: auto;
}

.inventory-storage-locations .action-button-filters .reset-filters-btn {
    min-width: 2.8571rem;
    min-height: 2.8571rem;
}

.inventory-storage-locations .storages-table .bullet-graph {
    height: 20px;
    width: 100%;
    overflow: hidden;
    border-radius: 0.3571rem;
}

.inventory-storage-locations .storages-table .bullet-graph svg, .inventory-storage-locations .storages-table .bullet-graph svg rect {
    width: 100%;
}

.inventory-storage-locations .storages-table .percentage-tag {
    width: 3.8571rem;
}

@media only screen and (max-width: 1200px) {
    .inventory-storage-locations .page-content .inventory-general-info .inventory-data {
        min-width: unset;
    }
  }

@media only screen and (max-width: 768px) {
    .inventory-storage-locations .storages-table .bullet-graph {
        border-radius: 0.7143rem 0.7143rem 0 0;
    }
  }