.popup-consult-inventory-deviation {
    min-width: 35vw;
    max-width: 48.5714rem;
}

.popup-consult-inventory-deviation .popup-content .popup-buttons {
    justify-content: flex-end;
}

.popup-consult-inventory-deviation .popup-content .popup-buttons .button {
    flex: unset;
    min-width: 9.5rem;
}

@media only screen and (max-width: 425px) {
    .popup-consult-inventory-deviation .popup-content .popup-buttons .button{
        flex: 1;
        min-width: unset;
    }
  }