.popup-move-item-count {
    min-width: 90vw;
}

.popup-move-item-count .card-container.titled.selected-item-count-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.popup-move-item-count .target-inventory-item-title {
    display: flex;
    flex-direction: column;
    gap: .4286rem;
}

.popup-move-item-count .filters {
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr auto;
    gap: 0 1.4286rem;
}

.popup-move-item-count .filters .upper-row-filters {
    display: flex;
    gap: 1.1429rem;
    flex-wrap: wrap;
}
  
.popup-move-item-count .filters .bottom-row-filters {
    grid-column: span 2;
    display: flex;
    flex-wrap: wrap;
    gap: 1.1429rem;
    height: 0;
    overflow-y: hidden;
}
  
.popup-move-item-count .filters .bottom-row-filters.expanded {
    height: auto;
    overflow-y: visible;
    margin-top: 1.1429rem;
}

.popup-move-item-count .filters .action-button-filters {
    justify-self: end;
    display: flex;
    justify-content: center;
    gap: 0.8571rem;
    margin-bottom: auto;
    height: 100%;
    align-items: flex-end;
}
  
.popup-move-item-count .filters .action-button-filters .view-more-filters-btn,
.popup-move-item-count .filters .action-button-filters .reset-filters-btn {
    min-width: 2.8571rem;
    min-height: 2.8571rem;
}

.popup-move-item-count .move-counts-table .info-box {
    display: flex;
    flex-direction: column;
    gap: .0714rem;
    color: var(--grey-palette-01);
}

.popup-move-item-count .move-counts-table .info-box .info span {
    color: var(--text-main-color);
}

.popup-move-item-count .special-attributes {
  display: flex;
  flex-direction: column;
  gap: .1429rem;
}

.popup-move-item-count .special-attributes .special-sap,
.popup-move-item-count .special-attributes .sap-type{
    display: flex;
    gap: 0.3rem; 
}

.popup-move-item-count .special-attributes .special-sap .label,
.popup-move-item-count .special-attributes .sap-type .label{
    color: var(--grey-palette-01);
}

.popup-move-item-count .deviation  {
  display: flex;
  flex-direction: column;
  gap: .1429rem;
}

.popup-move-item-count .deviation .qty-difference-block,
.popup-move-item-count .deviation .monetary-block {
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
}

.popup-move-item-count .deviation .qty-difference-block .label, .popup-move-item-count .deviation .monetary-block .label {
  color: var(--grey-palette-01);
}

.popup-move-item-count .deviation .qty-difference-block .value-red,
.popup-move-item-count .deviation .monetary-block .value-red {
    color: var(--error-color)
}

.popup-move-item-count .popup-buttons {
    justify-content: end;
}

@media only screen and (max-width: 768px) {
    .popup-move-item-count .filters {
      gap: 0 .7143rem;
    }
  
    .popup-move-item-count .filters .upper-row-filters {
      gap: .7143rem;
    }
    
    .popup-move-item-count .filters .bottom-row-filters {
      gap: .7143rem;
    }
  
    .popup-move-item-count .filters .action-button-filters {
      gap: .7143rem;
    }
  }

@media only screen and (max-width: 425px) {
    .popup-move-item-count .filters .upper-row-filters {
      flex-grow: 1;
    }
  
    .popup-move-item-count .filters .form-field {
      width: 100%;
    }
  
    .popup-move-item-count .filters .form-field .text-input {
      min-width: unset;
    }
  
    .popup-move-item-count .filters .form-field .text-input .native-input {
      width: 100%;
    }
  }