.popup-container.popup-edit-storage-manager-storages{
    overflow: visible;
} 

.card-container.edit-storage-manager-storages{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.card-container.edit-storage-manager-storages .info-block .info-block-value{
    word-break: break-word;
}