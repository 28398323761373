.navbar {
  width: 100%;
  min-height: 5.7143rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary-color);
  padding: 0 2.1429rem 0 1.7143rem;
}

.navbar .navbar-logo-project {
  display: flex;
  align-items: center;
  gap: 1.4286rem;
}

.navbar .navbar-logo-project .navbar-logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar .navbar-logo-project .navbar-logo:hover {
  opacity: 0.7;
}

.navbar .navbar-logo-project .project {
  font-weight: var(--font-weight-bold);
  color: #ffffff;
  opacity: 0.2;
}

.navbar .navbar-logo-project .project .app-version{
  font-size: small;
}

.navbar .navbar-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  color: #ffffff;
  gap: 2.8571rem;
}

.navbar .navbar-options .additional-options {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar .navbar-options .options {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8571rem;
}

.navbar .navbar-options .language {
  display: flex;
  cursor: pointer;
  font-weight: var(--font-weight-bold);
}
