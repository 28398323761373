.page-layout.admin-storage-locations .button.import-storage-locations,
.page-layout.admin-storage-locations .button.create-storage-location {
    min-width: 9.5rem;
}

.page-layout.admin-storage-locations .page-content {
    display: flex;
    flex-direction: column;
    gap: 0.8571rem;
}

.page-layout.admin-storage-locations .page-content .command-bar {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: .7143rem;
    grid-column-gap: 1.1429rem;
}

.page-layout.admin-storage-locations .page-content .command-bar .text-input.search-box {
    width: 19.7857rem;
}

.toggle-div {
    position: relative;
}

.toggle-div .toggle-div-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }