
.inventory-item-counts .page-layout-header-action-buttons .icon-button.primary .icon svg path:first-child {
    fill: none;
}

.inventory-item-counts .page-content .card-container.item-data {
    flex-grow: 1; 
}

.inventory-item-counts .page-content .card-container.item-data .item-details-header {
    display: flex;
    justify-content: space-between;
}

.inventory-item-counts .page-content .card-container.item-data .item-details-header .item-header-tags {
    display: flex;
    gap: 1rem;
}

.inventory-item-counts .page-content .card-container.item-data .item-details-header .title {
    font-size: 1.2857rem;
    font-weight: var(--font-weight-medium);
}

.inventory-item-counts .page-content .item-info {
    display: flex;
    flex-direction: row;
    gap: 1.4286rem;
}

.inventory-item-counts .page-content .item-info .item-data {
    height: auto;
}

.inventory-item-counts .page-content .item-info .status-cards {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 1.4286rem;
}

.inventory-item-counts .page-content .filters{
    display: flex;
    gap: 1.1429rem
}

.inventory-item-counts .page-content .counts-status {
    display: flex;
    gap: .4286rem;
}

.inventory-item-counts .page-content .counts-status .number-of-counts-tag {
    padding: 0.2857rem .5714rem;
}

@media only screen and (max-width: 425px) {
    .inventory-item-counts .page-content .card-container.item-data .item-details-header .item-header-tags {
        flex-direction: column;
        align-items: flex-end;
        gap: .2857rem;
    }
  }