.page-layout.admin-storage-managers .button.import-storage-manager,
.page-layout.admin-storage-managers .button.create-storage-manager {
    min-width: 9.5rem;
}

.page-layout.admin-storage-managers .page-content {
    display: flex;
    flex-direction: column;
    gap: 0.8571rem;
}

.page-layout.admin-storage-managers .page-content .command-bar {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: .7143rem;
    grid-column-gap: 1.1429rem;
}

.page-layout.admin-storage-managers .page-content .command-bar .text-input.search-box {
    width: 19.7857rem;
}