.sub-side-bar .icon {
  width: 2.1429rem;
  height: 2.1429rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sub-side-bar .icon.bottom-aligned {
  margin-top: auto;
}

.sub-side-bar .icon a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-side-bar .icon a svg {
  width: 100%;
}

.sub-side-bar .icon:hover a svg path {
  fill: var(--primary-color);
}

.sub-side-bar .icon a.selected svg path {
  fill: var(--primary-color);
}





/* ######################################################## */

.sub-side-bar-btn-2 a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .1429rem;
  text-decoration: none;
}

.sub-side-bar-btn-2 a .icon {
  width: 2.1429rem;
  height: 2.1429rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sub-side-bar-btn-2 a .icon.bottom-aligned {
  margin-top: auto;
}

.sub-side-bar-btn-2 a .icon svg {
  width: 100%;
  height: auto;
}

.sub-side-bar-btn-2 a:hover .icon svg path {
  fill: var(--primary-color);
}

.sub-side-bar-btn-2 a.selected svg path {
  fill: var(--primary-color);
}

.sub-side-bar-btn-2 a .label {
  word-wrap: break-word;
  text-align: center;
  font-weight: var(--font-weight-bold);
}

.sub-side-bar-btn-2 a:hover .label,
.sub-side-bar-btn-2 a.selected .label {
  color: var(--primary-color);
}
