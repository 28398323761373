.page-layout.admin-pda-operators .button.import-pda-operators,
.page-layout.admin-pda-operators .button.create-pda-operator {
    min-width: 9.5rem;
}

.page-layout.admin-pda-operators .page-content {
    display: flex;
    flex-direction: column;
    gap: .8571rem
}

.page-layout.admin-pda-operators .page-content .command-bar {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: .7143rem;
    grid-column-gap: 1.1429rem;
}

.page-layout.admin-pda-operators .page-content .command-bar .text-input.search-box {
    width: 19.7857rem;
}

.page-layout.admin-pda-operators .page-content .table-container .remove-manager-operator-association-icon svg g> :last-child {
    fill: var(--error-color)
}