.contextual-menu-host {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: fit-content;
}

.contextual-menu-panel {
  position: absolute;
  z-index: 10;
  display: none;
  flex-direction: column;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0.3571rem;
  padding: 0.2857rem 0;
  gap: 0.1429rem;
  background-color: var(--grey-palette-03);
  color: var(--secondary-color);
}

.contextual-menu-panel .menu-option {
  display: flex;
  align-items: center;
  height: 1.7143rem;
  gap: 0.2857rem;
  padding: 0 0.8571rem 0 0.4286rem;
}

.contextual-menu-host .menu-option.disabled,
.contextual-menu-host .menu-option.disabled:hover {
  opacity: 0.45;
  pointer-events: none;
}



.contextual-menu-panel .menu-option:hover {
  background-color: var(--grey-palette-02);
  cursor: pointer;
}

.contextual-menu-panel .menu-option .menu-option-icon {
  width: 1.1429rem;
  height: 1.1429rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contextual-menu-panel .menu-option .menu-option-icon svg {
  width: 100%;
  height: auto;
}

.contextual-menu-panel .menu-option .menu-option-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}