.inventories-home-page .page-content .inventories-table .mobile-table-row .status-cell .tag {
    width: 100%;
    border-radius: 0.7143rem 0.7143rem 0 0;
}

.remove-inventory-popup .popup-buttons .button.primary {
    background: var(--status-red);
}

.cancel-inventory-popup .popup-buttons .button.primary {
    background: var(--status-red);
}