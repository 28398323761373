.popup-add-item-comment {
    display: flex;
    flex-direction: column;
}

.popup-add-item-comment .popup-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.popup-add-item-comment .popup-content .comments {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
    gap: 1.1429rem;
}