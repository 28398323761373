.page-layout.materials-home .button.create-material,
.page-layout.materials-home .button.import-material {
    min-width: 9.5rem;
}

.page-layout.materials-home .page-content {
    display: flex;
    flex-direction: column;
    gap: 0.8571rem;
}

.page-layout.materials-home .page-content .command-bar {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: .7143rem;
    grid-column-gap: 1.1429rem;
}

.page-layout.materials-home .page-content .command-bar .text-input.search-box {
    width: 19.7857rem;
}

.popup-container.system-popup.warning-popup.disable-material-popup .popup-buttons .button.primary {
    background: var(--status-red);
  }