.page-layout.location-groups .page-content .access-groups-loader{
  display: flex;
  min-height: 100%;
  justify-content: center;
}

.page-layout.location-groups .page-content .table-container {
  width: 100%;
  height: 100%;
}

.page-layout.location-groups .page-content .group-access-table {
  width: 100%;
}

.page-layout.location-groups .page-content .group-access-table .table-container .table-row .table-cell.table-row-cell .form-field {
  width: 100%;
}

.page-layout.location-groups .page-content .mobile-table-container .mobile-table-rows-container .mobile-table-row .mobile-table-cell .mobile-table-cell-value .form-field .text-input,
.page-layout.location-groups .page-content .table-container .table-row .table-cell.table-row-cell .form-field .text-input {
  background-color: white;
}


.page-layout.location-groups .page-content .mobile-table-container .mobile-table-rows-container .mobile-table-row .mobile-table-cell .mobile-table-cell-value .form-field .text-input:not(:is(.error, :focus-within, :hover)),
.page-layout.location-groups .page-content .table-container .table-row .table-cell.table-row-cell .form-field .text-input:not(:is(.error, :focus-within, :hover)) {
  border-color: white;

}

.page-layout.location-groups .page-content .table-container .table-row:nth-child(even) {
  background-color: var(--grey-palette-02);
}

.page-layout.location-groups .page-content .table-container .table-row:hover {
  background-color: var(--grey-palette-02);
}