.popup-validate-item-count .selected-item-info {
    padding: 1rem;
}

.popup-validate-item-count .selected-item-info .block {
    display: flex;
    flex-direction: column;
    gap: .1429rem;
}

.popup-validate-item-count .selected-item-info .block .block-label {
    color: var(--grey-palette-01);
}

.popup-validate-item-count .selected-item-info .block .block-value {
    font-weight: var(--font-weight-bold);
}

.popup-validate-item-count .warning-text {
    color: var(--status-red);
    font-weight: var(--font-weight-bold);
}