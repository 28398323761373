.popup-add-inventory-item {
    min-width: 35vw;
    max-width: 48.5714rem;
}

.popup-add-inventory-item .popup-content .popup-buttons {
    justify-content: flex-end;
}

.popup-add-inventory-item .popup-content .popup-buttons .button {
    flex: unset;
    min-width: 9.5rem;
}


.popup-add-inventory-item .popup-content .inexisting-material-alert {
    flex: auto;
    width: 100%;
}

.popup-add-inventory-item .popup-content .import-loading .loader-container {
        flex: none;
        position: relative;
        transform: none;
        top: auto;
        left: auto;
    
}

.popup-add-inventory-item .popup-content .inexisting-storage-alert {
    flex: auto;
    width: 100%;
}

@media only screen and (max-width: 425px) {
    .popup-add-inventory-item .popup-content .popup-buttons .button {
        flex: 1;
        min-width: unset;
    }
}