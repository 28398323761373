.import-loading {
    display: flex;
    align-items: center;
    gap: .7143rem;
    color: var(--grey-palette-01);
    font-weight: var(--font-weight-bold);
}

.import-loading .loader-container {
    flex: none;
}

.import-loading .loader-container .loader {
    width: 1.6429rem;
    height: 2rem;
    gap: .1429rem;
}