.inventory-items-page .page-content .card-container.storage-data .storage-details-header {
    display: flex;
    justify-content: space-between;
}

.inventory-items-page .page-content .card-container.storage-data .storage-details-header .title {
    font-size: 1.2857rem;
    font-weight: var(--font-weight-medium);
}

.inventory-items-page .page-content .storage-general-info {
    display: flex;
    flex-direction: row;
    gap: 1.4286rem;
}

.inventory-items-page .page-content .storage-general-info .storage-data {
    height: auto;
    min-width: 35.7143rem;
    flex: 1;
}

.inventory-items-page .page-content .storage-general-info .status-cards {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 1.4286rem;
}

.inventory-items-page .page-content .command-bar {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-row-gap: .7143rem;
    grid-column-gap: 1.1429rem;
}

.inventory-items-page .page-content .filters {
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr auto;
    gap: 0 1.4286rem;
}

.inventory-items-page .page-content .filters .upper-row-filters {
    display: flex;
    gap: 1.1429rem;
    flex-wrap: wrap;
}
  
.inventory-items-page .page-content .filters .bottom-row-filters {
    grid-column: span 2;
    display: flex;
    flex-wrap: wrap;
    gap: 1.1429rem;
    height: 0;
    overflow-y: hidden;
}
  
.inventory-items-page .page-content .filters .bottom-row-filters.expanded {
    height: auto;
    overflow-y: visible;
    margin-top: 1.1429rem;
}

.inventory-items-page .page-content .filters .action-button-filters {
    justify-self: end;
    display: flex;
    justify-content: center;
    gap: 0.8571rem;
    margin-bottom: auto;
    height: 100%;
    align-items: flex-end;
}
  
.inventory-items-page .page-content .filters .action-button-filters .view-more-filters-btn,
.inventory-items-page .page-content .filters .action-button-filters .reset-filters-btn {
    min-width: 2.8571rem;
    min-height: 2.8571rem;
}

.inventory-items-page .page-content .items-table .table-column-header, .inventory-items-page .page-content .items-table .table-row .table-cell {
    padding: .7143rem .5714rem;
    font-size: 0.8571rem;
}

.inventory-items-page .page-content .items-table .table-row .table-cell .info-box {
    display: flex;
    flex-direction: column;
    gap: .0714rem;
    color: var(--grey-palette-01);
}

.inventory-items-page .page-content .items-table .table-row .table-cell .info-box .info span {
    color: var(--text-main-color);
}

.inventory-items-page .page-content .counts-status {
    display: flex;
    gap: .4286rem;
}

.inventory-items-page .page-content .counts-status .number-of-counts-tag {
    padding: 0.2857rem .5714rem;
}

.inventory-items-page .special-attributes {
    display: flex;
    flex-direction: column;
    gap: .1429rem;
}

.inventory-items-page .special-attributes .special-sap,
.inventory-items-page .special-attributes .sap-type{
    display: flex;
    gap: 0.3rem; 
}

.inventory-items-page .special-attributes .special-sap .label,
.inventory-items-page .special-attributes .sap-type .label{
    color: var(--grey-palette-01);
}

.inventory-items-page .page-content .deviation {
    display: flex;
    flex-direction: column;
    gap: .1429rem;
}

.inventory-items-page .page-content .deviation .qty-difference-block, .inventory-items-page .page-content .deviation .monetary-block {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
}

.inventory-items-page .page-content .deviation .qty-difference-block .label, .inventory-items-page .page-content .deviation .monetary-block .label {
    color: var(--grey-palette-01);
}

.inventory-items-page .page-content .deviation .qty-difference-block, 
.inventory-items-page .page-content .deviation .monetary-block{
    display: flex;
    gap: 0.3rem
}

.inventory-items-page .page-content .deviation .qty-difference-block .value-red,
.inventory-items-page .page-content .deviation .monetary-block .value-red{
    color: var(--error-color)
}

.inventory-items-page .page-content .items-table .mobile-table-row .status-cell .tag {
    width: 100%;
    border-radius: 0.7143rem 0.7143rem 0 0;
}

@media only screen and (max-width: 1200px) {
    .inventory-items-page .page-content .storage-general-info .storage-data {
        min-width: unset;
    }
}

@media only screen and (max-width: 768px) {
    .inventory-items-page .page-content .filters {
      gap: 0 .7143rem;
    }
  
    .inventory-items-page .page-content .filters .upper-row-filters {
      gap: .7143rem;
    }
    
    .inventory-items-page .page-content .filters .bottom-row-filters {
      gap: .7143rem;
    }
  
    .inventory-items-page .page-content .filters .action-button-filters {
      gap: .7143rem;
    }
}

@media only screen and (max-width: 425px) {
    .inventory-items-page .page-content .filters .upper-row-filters {
      flex-grow: 1;
    }
  
    .inventory-items-page .page-content .filters .form-field {
      width: 100%;
    }
  
    .inventory-items-page .page-content .filters .form-field .text-input {
      min-width: unset;
    }
  
    .inventory-items-page .page-content .filters .form-field .text-input .native-input {
      width: 100%;
    }
}