.message-bar {
    display: flex;
    gap: .2857rem;
    padding: .4286rem;
    border-radius: .2857rem;
    border: 1px solid var(--grey-palette-01);
    background-color: var(--grey-palette-02);
}

.message-bar.success {
    border: 1px solid var(--success-color);
    background-color: #ebfbea;
}

.message-bar.success .message-bar-icon svg path {
    fill: var(--success-color);
}

.message-bar.success .message-bar-text {
    color: var(--success-color);
}

.message-bar.error {
    border: 1px solid var(--error-color);
    background-color: #ffe7e9;
}

.message-bar.error .message-bar-icon svg path {
    fill: var(--error-color);
}

.message-bar.error .message-bar-text {
    color: var(--error-color);
}

.message-bar.warning {
    border: 1px solid #e1c317;
    background-color: #fffcea;
}

.message-bar.warning .message-bar-icon svg path {
    fill: #e1c317
}

.message-bar.warning .message-bar-text {
    color: #e1c317
}

.message-bar .message-bar-icon {
    min-width: 1rem;
    min-height: 1rem;
    max-width: 1rem;
    max-height: 1rem;
}

.message-bar .message-bar-icon svg {
    width: 100%;
    height: auto;
}

.message-bar .message-bar-icon svg path {
    fill: var(--secondary-color);
}
