.pda-simulator-page .form-field {
    max-width: 21.4286rem;
}

.pda-simulator-page .storage-items {
    color: var(--grey-palette-01);
}

.pda-simulator-page .simulator-actions {
    display: flex;
    flex-direction: row;
    gap: 1.1429rem;
}

.pda-simulator-page .error-message {
    color: var(--error-color);
}

.pda-simulator-page .item-info {
    width: -moz-fit-content;
    width: fit-content;
}
