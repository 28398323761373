:root {
  --bg-main-color: #ffffff;

  --primary-color: #51af4c;
  --secondary-color: #4b4b4a;
  --secondary-color-transparency-8: rgba(70, 70, 70, 0.8);

  --gradient-main: transparent
    linear-gradient(122deg, var(--primary-color) 0%, #82db7e 100%) 0% 0%
    no-repeat padding-box;

  --success-color: #51af4c;
  --error-color: #ea7c84;
  --warning-color: #fded94;

  --grey-palette-01: #b9b8b8;
  --grey-palette-02: #f4f4f4;
  --grey-palette-03: #fafafa;

  --text-main-color: var(--secondary-color);

  --text-hover-color: var(--primary-color);
  --text-error-color: var(--error-color);

  --placeholder-color: var(--grey-palette-01);
  --placeholder-disabled-color: rgba(120, 124, 124, 0.5);

  --border-main-color: var(--secondary-color);

  --status-red: #ea7c84;
  --status-red-transparency-3: rgba(234, 124, 132, 0.3);
  --status-blue: #8ecbe6;
  --status-blue-transparency-3: rgba(142, 203, 230, 0.3);
  --status-green: var(--primary-color);
  --status-green-transparency-3: rgba(81, 175, 76, 0.3);
  --status-grey: var(--grey-palette-01);
  --status-grey-transparency-3: rgba(185, 184, 184, 0.3);
  --status-yellow: #fded94;
  --status-yellow-transparency-3: rgba(253, 237, 148, 0.3);
  --status-yellow-transparency-4: rgba(253, 237, 148, 0.4);
  --status-lime-green: #7FFCB4;
  --status-dark-grey: var(--secondary-color);
  --status-deep-blue: #008ABA;
  --status-deep-blue-transparency-3: rgba(0,138,186,0.3);



  --color-valmet-deep-blue: #008ABA;
  --color-valmet-deep-blue-transparency-3: #008ABA4D;
  --color-valmet-bright-blue: #00B4E4;
  --color-valmet-sky-blue: #8FCAE7;
  --color-valmet-sky-blue-transparency-3: #8FCAE74D;
  --color-valmet-light-blue: #C2DEEA;
  --color-valmet-green: #50B948;
  --color-valmet-bright-green: #96D591;
  --color-valmet-nature-green: #DAE5CD;

}

body {
  background-color: var(--bg-main-color);
}
